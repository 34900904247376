import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Button, Heading } from '@qasa/qds-ui'

import img from '../../../assets/images/landing_page_report.jpg'
import { EXTERNAL_LINKS } from '../../../brands/external-links'
import { currentBrand } from '../../../brands'
import { Link } from '../../../vendor/next'

import { BackgroundImage } from './background-image'

const HeadingSection = styled(Heading)(({ theme }) => ({
  flexGrow: 1,
  color: theme.colors.core.white,
  ...theme.typography.display.xs,
  [theme.mediaQueries.lgUp]: theme.typography.display.md,
  [theme.mediaQueries['2xlUp']]: theme.typography.display.xl,
}))

export function PressRoomSection() {
  const { t } = useTranslation('report')
  const translationOptions = { context: currentBrand }

  return (
    <BackgroundImage img={img.src} label={t('label', translationOptions)}>
      <HeadingSection>{t('heading', translationOptions)}</HeadingSection>
      <Button as={Link} size={'xl'} variant={'tertiary'} href={EXTERNAL_LINKS.press as string}>
        {t('cta')}
      </Button>
    </BackgroundImage>
  )
}

'use client'

import styled from '@emotion/styled'
import { Spacer, Stack } from '@qasa/qds-ui'
import { useEffect, useState } from 'react'

import { PopularPlaces } from '../../ui-page-modules/landing/popular-places'
import { RoleSpecificUsps } from '../../ui-page-modules/landing/role-specific-usps'
import { ListOfSEOLinks } from '../../ui-page-modules/landing/list-of-seo-links'
import { TopSection } from '../../ui-page-modules/landing/top-section'
import { UspSection } from '../../ui-page-modules/landing/usp-section'
import { PageWidthContainer } from '../../ui-shared/_layout/width-containers'
import { MetaTags } from '../../ui-page-modules/meta-tags'
import { useBrandDefaultCanonicalUrl } from '../../brands/canonical-url'
import { currentBrand } from '../../brands'
import { usePathname } from '../../vendor/next'

import { LandingPageContent } from './landing-page-content'

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const Content = styled(PageWidthContainer)({ padding: '0 16px' })

const useLandingPageCanonicalUrl = () => {
  const defaultCanonicalUrl = useBrandDefaultCanonicalUrl()
  const pathname = usePathname()
  /* We want to make an exception here for blocket -> qasa canonicals */
  if (currentBrand === 'blocket') {
    return `https://bostad.blocket.se${pathname}`
  }

  return defaultCanonicalUrl
}

export function Landing() {
  const canonicalUrl = useLandingPageCanonicalUrl()
  const isBlocket = currentBrand === 'blocket'
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (isBlocket && !isClient) {
    return null
  }

  return (
    <Wrapper>
      <MetaTags canonicalUrl={canonicalUrl} />
      {isBlocket ? (
        <>
          <TopSection />
          <Content maxWidth={'widest'}>
            <Spacer size={{ base: '16x', md: '20x' }} />
            <PopularPlaces />
            <Spacer size={{ base: '4x', md: '20x' }} />
            <UspSection />
            <Spacer size={{ base: '12x', md: '20x' }} />
            <Stack gap={'12x'}>
              <RoleSpecificUsps userRole="landlord" />
              <RoleSpecificUsps userRole="tenant" />
            </Stack>
            <Spacer size={{ base: '16x', md: '20x' }} />
            <ListOfSEOLinks />
          </Content>
        </>
      ) : (
        <LandingPageContent />
      )}
    </Wrapper>
  )
}

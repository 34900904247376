import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Fragment } from 'react'
import { Heading, Paragraph, Spacer, Stack } from '@qasa/qds-ui'

import { Image } from '../../ui-shared/_core/image'
import contractImg from '../../assets/illustrations/blocket/contract.svg'
import supportImg from '../../assets/illustrations/blocket/safety.svg'
import rentImg from '../../assets/illustrations/blocket/money-wagon.svg'
import { BRAND_CONFIG } from '../../brands/brand-configurations'

const Wrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.colors.bg.inset,
  borderRadius: theme.radii.md,
  padding: theme.spacing['6x'],
  paddingTop: theme.spacing['8x'],
  paddingBottom: theme.spacing['6x'],
  margin: '0 -32px',
  [theme.mediaQueries.mdUp]: {
    paddingBottom: theme.spacing['16x'],
    margin: 0,
  },
}))
const UspListWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.mediaQueries.mdUp]: { flexDirection: 'row' },
}))
const Usp = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  textAlign: 'center',
  padding: '0 24px',
  [theme.mediaQueries.mdUp]: {
    width: '33%',
  },
}))
const UspImage = styled(Image)({ width: 88, height: 88 })

const USPS = [
  { id: 'digital_lease', image: contractImg, altI18nKey: 'contract' },
  { id: 'rent_on_time', image: rentImg, altI18nKey: 'money_wagon' },
  { id: 'support', image: supportImg, altI18nKey: 'safety' },
]

function UspList() {
  const { t } = useTranslation('landing_usp_section')
  return (
    <UspListWrapper>
      {USPS.map(({ id, image, altI18nKey }, index) => {
        return (
          <Fragment key={id}>
            {index > 0 && <Spacer size="12x" />}
            <Usp key={id}>
              <UspImage alt={t(`image_alt_tags:illustrations.blocket.${altI18nKey}`)} src={image} />
              <Spacer size="6x" />
              <Heading textAlign="center" as="h3" size="sm">
                {t(`${id}.title`)}
              </Heading>
              <Spacer size="2x" />
              <Paragraph textAlign="center">{t(`${id}.description`)}</Paragraph>
            </Usp>
          </Fragment>
        )
      })}
      <Spacer size="2x" />
    </UspListWrapper>
  )
}

export function UspSection() {
  const { t } = useTranslation('landing_usp_section')
  return (
    <Wrapper gap="12x" id="general-usps">
      <Heading textAlign="center" as="h2">
        {t('title', { brandName: BRAND_CONFIG.name })}
      </Heading>
      <UspList />
    </Wrapper>
  )
}

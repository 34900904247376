import styled from '@emotion/styled'
import { Stack, ArrowRightIcon, ArrowLeftIcon, pxToRem, theme } from '@qasa/qds-ui'
import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { AnimatePresence, motion } from 'framer-motion'

import { currentBrand } from '../../../brands'
import type { BrandType } from '../../../brands/index.types'

const Wrapper = styled(Stack)(({ theme }) => ({
  padding: `${theme.spacing['8x']} ${theme.spacing['6x']}`,
  height: '80vh',
  position: 'relative',
  minHeight: 500,
  backgroundColor: theme.colors.core.softYellow,
  [theme.mediaQueries.xlUp]: {
    padding: `${theme.spacing['24x']} 10%`,
  },
}))

const Quote = styled(motion.p)(({ theme }) => ({
  whiteSpace: 'pre-line',
  textAlign: 'center',
  textWrap: 'pretty',
  maxWidth: '40ch',
  ...theme.typography.display.xs,
  [theme.mediaQueries.xlUp]: {
    ...theme.typography.display.md,
  },
}))

const NavButton = styled.button(({ theme }) => ({
  height: 64,
  width: 64,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  bottom: theme.spacing['8x'],
  transition: 'transform 0.2s ease-in-out',
  [theme.mediaQueries.xlUp]: {
    bottom: 'initial',
  },
  '@media(hover: hover)': {
    ':hover': {
      transform: 'scale(1.25)',
    },
    ':active': {
      transform: 'scale(1.15)',
    },
  },
  '> svg': {
    fontSize: pxToRem(48),
  },
}))

const PrevButton = styled(NavButton)({
  left: theme.spacing['16x'],
})
const NextButton = styled(NavButton)({
  right: theme.spacing['16x'],
})

export function Testimonial() {
  const { t } = useTranslation('testimonial')
  const [currentIndex, setCurrentIndex] = useState(0)

  const testimonialsByBrand: Record<BrandType, string[]> = {
    qasa: t('items', { returnObjects: true }),
    qasa_finland: t('items_qasa_finland', { returnObjects: true }),
    qasa_france: t('items_qasa_france', { returnObjects: true }),
    dotcom: ['items', 'items_qasa_finland', 'items_qasa_france']
      .map((key) => t(key, { returnObjects: true }))
      .flat() as string[],
    blocket: [],
  }
  const quotes = testimonialsByBrand[currentBrand]

  const numberOfQuotes = quotes.length

  const goNext = useCallback(() => {
    setCurrentIndex((oldIndex) => (oldIndex + 1) % numberOfQuotes)
  }, [numberOfQuotes])

  const goBack = () => {
    setCurrentIndex((oldIndex) => (oldIndex - 1 + numberOfQuotes) % numberOfQuotes)
  }

  useEffect(() => {
    const quoteLength = quotes[currentIndex].length
    // NOTE: Time based on: https://ux.stackexchange.com/questions/85882/for-how-long-should-alerts-be-displayed/85897#85897
    const duration = quoteLength * 50 + 2000
    const timer = setTimeout(() => {
      goNext()
    }, duration)

    return () => {
      clearTimeout(timer)
    }
  }, [currentIndex, goNext, quotes])

  return (
    <Wrapper alignItems="center" justifyContent="center">
      <PrevButton onClick={goBack}>
        <ArrowLeftIcon />
      </PrevButton>
      <AnimatePresence mode="wait">
        <Quote
          key={currentIndex}
          transition={{ duration: 0.3, ease: 'easeIn' }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {quotes[currentIndex]}
        </Quote>
      </AnimatePresence>
      <NextButton onClick={goNext}>
        <ArrowRightIcon />
      </NextButton>
    </Wrapper>
  )
}

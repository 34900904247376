import styled from '@emotion/styled'
import { Button, Heading, Spacer, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import heroImage from '../../../../assets/images/landing_page_chair_by_window.jpg'
import mobileHeroImage from '../../../../assets/images/landing_page_chair_by_window_mobile.jpg'
import loadingHeroImage from '../../../../assets/images/landing_page_chair_by_window_loading.jpg'
import { currentBrand } from '../../../../brands'
import { ResponsiveImage } from '../responsive-image'
import { EXTERNAL_LINKS } from '../../../../brands/external-links'

import { SearchForm } from './search-form'

const HERO_HEIGHT = '85vh'
const HERO_HEIGHT_MIN = 600

// eslint-disable-next-line @typescript-eslint/naming-convention
const IS_QASA_FRANCE = currentBrand === 'qasa_france'

const HeroWrapper = styled.div({
  position: 'relative',
  height: HERO_HEIGHT,
  minHeight: HERO_HEIGHT_MIN,
})

const OverlayContent = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  height: HERO_HEIGHT,
  minHeight: HERO_HEIGHT_MIN,
  inset: 0,
  padding: `${theme.spacing['12x']} ${theme.spacing['6x']}`,
  [theme.mediaQueries.mdUp]: {
    padding: theme.spacing['16x'],
  },
}))

const ContentWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: IS_QASA_FRANCE ? 'center' : 'space-between',
  width: '100%',
  flex: 1,
  [theme.mediaQueries.mdUp]: {
    justifyContent: 'center',
  },
}))

const Image = styled(ResponsiveImage)({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  background: `url(${loadingHeroImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
})

const Wrapper = styled(Stack)(({ theme }) => ({
  maxWidth: '80ch',
  [theme.mediaQueries.mdUp]: {
    marginLeft: '28px',
    marginRight: theme.spacing['6x'],
    marginBottom: theme.spacing['12x'],
  },
}))

const DisplayHeading = styled(Heading)(({ theme }) => ({
  color: theme.colors.core.white,
  letterSpacing: '-0.04em',
  fontSize: 60,
  lineHeight: 1,
  whiteSpace: 'pre-wrap',
  hyphens: 'auto',
  [theme.mediaQueries.mdUp]: {
    fontSize: 80,
  },
  '@media (max-width: 360px)': {
    fontSize: 50,
  },
}))

const Description = styled.p(({ theme }) => ({
  ...theme.typography.display.xs,
  color: theme.colors.core.white,
  whiteSpace: 'pre-wrap',
  hyphens: 'auto',
  fontWeight: 400,
  [theme.mediaQueries.mdUp]: {
    ...theme.typography.display.sm,
    fontWeight: 400,
  },
}))

export function Hero() {
  const { t } = useTranslation('hero')
  const translationOptions = { context: currentBrand }

  // Currently, the description is only used for the French landing page
  const description = t('description', translationOptions)
  return (
    <HeroWrapper>
      <Image
        src={mobileHeroImage}
        alt="A chair by a window"
        loading="eager"
        fetchPriority="high"
        srcSet={{ 1920: heroImage, 690: mobileHeroImage }}
        sizes="100vw"
      />
      <OverlayContent justifyContent={'space-between'}>
        <ContentWrapper>
          {!IS_QASA_FRANCE && <Spacer size={{ base: '2x', md: '0x' }} />}
          <Wrapper gap="6x">
            {/* @ts-ignore - needed because 'as' property doesn't work on styled heading */}
            <DisplayHeading as="h1">{t('title', translationOptions)}</DisplayHeading>
            {description && <Description>{description}</Description>}
            {IS_QASA_FRANCE && (
              <Stack wrap="wrap" gap="4x" direction="row">
                <Button
                  as="a"
                  variant="tertiary"
                  style={{ alignSelf: 'flex-start' }}
                  href={EXTERNAL_LINKS.recontactForm}
                >
                  {t('recontact_cta')}
                </Button>
                <Button
                  as="a"
                  variant="tertiary"
                  style={{ alignSelf: 'flex-start' }}
                  href={EXTERNAL_LINKS.leaseTakeoverForm}
                >
                  {t('lease_takeover_cta')}
                </Button>
              </Stack>
            )}
          </Wrapper>
          {!IS_QASA_FRANCE && <SearchForm />}
        </ContentWrapper>
      </OverlayContent>
    </HeroWrapper>
  )
}

import styled from '@emotion/styled'
import { Button, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { EXTERNAL_LINKS } from '../../../brands/external-links'

const Wrapper = styled.section(({ theme }) => ({
  backgroundColor: theme.colors.core.softYellow,
  padding: `${theme.spacing['12x']} ${theme.spacing['6x']}`,
  [theme.mediaQueries.mdUp]: {
    padding: `${theme.spacing['24x']} 10%`,
  },
}))

const Title = styled.h2(({ theme }) => ({
  whiteSpace: 'pre-line',
  textAlign: 'center',
  ...theme.typography.display.xs,
  [theme.mediaQueries.mdUp]: {
    ...theme.typography.display.sm,
  },
  [theme.mediaQueries.xlUp]: {
    ...theme.typography.display.md,
  },
}))
const Description = styled.p(({ theme }) => ({
  ...theme.typography.body.xl,
  textAlign: 'center',
  textWrap: 'pretty',
  maxWidth: '64ch',
}))

export function Partnerships() {
  const { t } = useTranslation('partnerships')
  const partnerShipURL = EXTERNAL_LINKS['partnerShip']
  return (
    <Wrapper>
      <Stack
        gap="12x"
        alignItems="center"
        style={{
          // TODO: Create shared component for this
          maxWidth: 1200,
          margin: '0 auto',
        }}
      >
        <Stack gap="8x">
          <Title>{t('title')}</Title>
          <Description>{t('description')}</Description>
        </Stack>
        {partnerShipURL && (
          <Button as="a" href={partnerShipURL} target="_blank">
            {t('cta')}
          </Button>
        )}
      </Stack>
    </Wrapper>
  )
}

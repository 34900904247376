import styled from '@emotion/styled'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DEFAULT_FILTER_VALUES, SearchHistoryProvider } from '@qasa/app'
import { HomeRentalTypeEnum } from '@qasa/graphql'
import { getSearchQueryString } from '@qasa/app/src/utils/search'

import { useRouter } from '../../../../vendor/next'
import { getPath } from '../../../../routing/get-path'
import { SearchTabEnum } from '../../../../ui-page-modules/landing/search-box-tabs'
import { currentBrand } from '../../../../brands'
import { LinkButtonV2 } from '../../../../ui-shared/_core/link-button-v2'

import { AreaSearchAutocompleteLanding } from './area-search-autocomplete'

const Form = styled.form(({ theme }) => ({
  textAlign: 'center',
  [theme.mediaQueries.mdUp]: {
    textAlign: 'left',
    display: 'flex',
  },
}))

const AreaSearchWrapper = styled.div(({ theme }) => ({
  minWidth: theme.sizes[512],
  maxWidth: '80vw',
}))

const SearchFormContainer = styled.div(({ theme }) => ({
  display: 'none',
  [theme.mediaQueries.mdUp]: {
    display: 'block',
  },
}))

const SearchButtonContainer = styled.div(({ theme }) => ({
  display: 'block',
  [theme.mediaQueries.mdUp]: {
    display: 'none',
  },
}))

export function SearchForm() {
  const [filterValues, setFilterValues] = useState(DEFAULT_FILTER_VALUES)
  const { push } = useRouter()
  const translationOptions = { context: currentBrand }
  const { t } = useTranslation('pop_content')

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    push(`/${SearchTabEnum.LONGTERM}${getSearchQueryString({ filterValues })}`)
  }

  return (
    <Form onSubmit={handleSubmit}>
      <SearchFormContainer>
        <SearchHistoryProvider rentalType={HomeRentalTypeEnum.long_term}>
          <AreaSearchWrapper>
            <AreaSearchAutocompleteLanding
              value={filterValues.searchAreas}
              onChange={(newValue) => setFilterValues((old) => ({ ...old, searchAreas: newValue }))}
            />
          </AreaSearchWrapper>
        </SearchHistoryProvider>
      </SearchFormContainer>

      <SearchButtonContainer>
        <LinkButtonV2 size="xl" variant="tertiary" href={getPath('findHome')}>
          {t('cta', translationOptions)}
        </LinkButtonV2>
      </SearchButtonContainer>
    </Form>
  )
}
